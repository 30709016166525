import { BID_TYPE_OPTIONS, CONCEPT_OPTIONS } from '@/forms/selectOptions'
import { getPropertyConcept, updatePropertyConcept } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    component: 'div',
    class: 'tw-my-4 tw-max-w-xl',
    children: [
      {
        type: 'select',
        name: 'type',
        label: 'Concept',
        placeholder: 'Selecteer concept',
        options: CONCEPT_OPTIONS,
        '@change': 'conceptTypeChange'
      },
      {
        component: 'div',
        id: 'bidType',
        class: 'tw-my-4 tw-my-4 tw-hidden',
        children: [
          {
            type: 'select',
            name: 'bid_type',
            label: 'Biedingstype',
            placeholder: 'Selecteer biedingstype',
            options: BID_TYPE_OPTIONS
          }
        ]
      },
      {
        component: 'div',
        id: 'rentToBuyPrice',
        class: 'tw-my-4 tw-my-4 tw-hidden',
        children: [
          {
            type: 'number',
            name: 'amount1',
            label: 'Huurprijs Rent to buy',
            placeholder: 'Huurprijs Rent to buy',
            min: '1.00',
            step: '0.01',
            lang: 'nl',
            validation: 'bail|number|min:0'
          }
        ]
      }
    ]
  }
]

export const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyConcept(propertyId)
      return response.data
    } catch (error) {
      errorModal('Fout bij het laden van de conceptdetails, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const response = await updatePropertyConcept(propertyId, data)
      successModal('De wijzigingen aan het concept zijn succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Er ging iets fout bij het wijzigen van het concept van dit pand. Gelieve nogmaals te proberen.')
      throw error
    }
  }
}

export default {
  heading: 'Concept',
  schema,
  actions
}
